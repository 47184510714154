import React from "react";
import * as S from "./styled";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { setStateDrawerAtom } from "../../ViewModels/DrawerViewModel";
import { useAtom } from "jotai";
import { useLocation } from "react-router-dom";
import { chatbotMenuAtom, isFromIpsispaceAtom } from "../../Atoms/RootAtom";
import { useGetFlowDataQuery } from "../../Queries/ChatbotQueries";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  handleClickAtom,
  handleCloseAndChangeTypeAtom,
  handleCloseAtom,
} from "../../ViewModels/RootViewModel";
import { currentChatbotAtom } from "../../Atoms/ChatAtom";

export function Header() {
  const [, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [isFromIpsispace] = useAtom(isFromIpsispaceAtom);
  const [currentChatbot] = useAtom(currentChatbotAtom);

  const [chatbotMenu] = useAtom(chatbotMenuAtom);
  const [, handleClick] = useAtom(handleClickAtom);
  const [, handleClose] = useAtom(handleCloseAtom);
  const [, handleCloseAndChange] = useAtom(handleCloseAndChangeTypeAtom);

  const location = useLocation();

  const { data: myChatbotList } = useGetFlowDataQuery("edueclassol@gmail.com");

  return (
    <>
      <S.CustomMenu
        anchorEl={chatbotMenu}
        id="chatbot-menu"
        open={Boolean(chatbotMenu)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }} // 변경
        anchorOrigin={{ horizontal: "left", vertical: "top" }} // 변경
      >
        {myChatbotList?.map((chatbot, index) => (
          <MenuItem
            onClick={() => handleCloseAndChange(chatbot)}
            key={`${index}-my-chatbot-list-header`}
          >
            <Avatar
              src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${chatbot.character_image?.file_path}`}
            />
            <Typography sx={{ fontSize: "14px", marginLeft: "15px" }}>
              {chatbot.name}
            </Typography>
          </MenuItem>
        ))}
      </S.CustomMenu>

      <S.HeaderContainer
        ishome={location.pathname === "/" ? "true" : "false"}
        isfromipsispace={isFromIpsispace ? "true" : "false"}
      >
        <S.HeaderInnerContainer gap="10px" justify="start" minwidth="50px">
          {isFromIpsispace && (
            <IconButton
              onClick={(e) => toggleDrawer(true, e)}
              sx={{ color: location.pathname === "/" ? "white" : "black" }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </S.HeaderInnerContainer>

        <S.HeaderInnerContainer gap="10px" justify="center" minwidth="221px">
          {currentChatbot && (
            <Box
              sx={{
                padding: "5px 15px",
                borderRadius: "30px",
                color: "rgb(0,32,96)",
                fontSize: "14px",
                fontWeight: "bold",
                border: "1px solid rgb(0,32,96)",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={(e) => handleClick(e)}
            >
              {currentChatbot.name}
              <KeyboardArrowDownIcon />
            </Box>
          )}
        </S.HeaderInnerContainer>

        <S.HeaderInnerContainer gap="10px" justify="end" minwidth="50px">
          {/* {currentChatbot && (
            <Avatar
              src={`https://dashboard.dokgabi.ai${currentChatbot.character_image?.file_path}`}
            />
          )} */}
          {!isFromIpsispace && (
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "rgb(0,32,96)",
                "&:hover": {
                  background: "white",
                  color: "rgb(0,32,96)",
                },
              }}
              onClick={() =>
                (window.location.href = "https://ipsispace.com/login/")
              }
            >
              로그인하러 가기
            </Button>
          )}
        </S.HeaderInnerContainer>
      </S.HeaderContainer>
    </>
  );
}
