import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { setStateDrawerAtom } from "../../../ViewModels/DrawerViewModel";
import { isFromIpsispaceAtom } from "../../../Atoms/RootAtom";

export function NoChatbot() {
  const [, toggleDrawer] = useAtom(setStateDrawerAtom);

  return (
    <Stack
      sx={{
        background: "white",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          objectFit: "cover",
          width: "100%", // 부모 요소의 너비에 맞춰 이미지가 크기를 조정
          maxWidth: "300px", // 최대 너비 설정 (필요에 따라 값 조정 가능)
          height: "auto", // 이미지의 비율을 유지하면서 높이를 자동 조정
        }}
        src="logo_origin.png"
        alt="logo"
      />
      <Box
        sx={{
          marginTop: "10px",
          padding: "10px 30px",
          borderRadius: "30px",
          color: "white",
          background: "rgb(0,32,96)",
          cursor: "pointer",
        }}
        onClick={(e) => toggleDrawer(true, e)}
      >
        챗봇을 선택해주세요.
      </Box>
    </Stack>
  );
}
