import React from "react";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useAtom } from "jotai";
import {
  handleWhenChatbotClickAtom,
  setStateDrawerAtom,
} from "../../ViewModels/DrawerViewModel";
import CloseIcon from "@mui/icons-material/Close";
import * as S from "./styled";
import { useNavigate } from "react-router-dom";
import { useGetFlowDataQuery } from "../../Queries/ChatbotQueries";

export function CustomDrawer() {
  const [drawerOpen, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [currentChatbot, handleWhenChatbotClick] = useAtom(
    handleWhenChatbotClickAtom
  );

  const navigate = useNavigate();

  const chatbotList = [
    "jfsbwvmktD9buhlF84YxrgTPlvDFPI5rW8G6",
    "5WTLBjCMFS6Bg4cqCvfGdMaru7459q3EvJKc",
    "epTwY0RyJkf2b6MwxCVduyqAdjiDpmwMZBFP",
    "8hrwxCg4BeBh8GEkeyhPuwRgNQ9P1hcHaumH",
  ];

  const { data: myChatbotList } = useGetFlowDataQuery("edueclassol@gmail.com");

  const sortedChatbotList = chatbotList
    .map(
      (id) =>
        myChatbotList &&
        myChatbotList.find((chatbot) => chatbot.chatbot_socket_parameter === id)
    )
    .filter(Boolean);

  if (sortedChatbotList === null || sortedChatbotList === undefined)
    return null;

  return (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClick={(e) => toggleDrawer(false, e)}
    >
      <S.CustomList
        onClick={(e) => toggleDrawer(false, e)}
        onKeyDown={(e) => toggleDrawer(false, e)}
      >
        <Stack
          direction={"row"}
          sx={{
            justifyContent: "space-between",
            padding: "5px 10px",
            marginTop: "15px",
          }}
        >
          <Box
            component={"img"}
            src="logo_origin.png"
            sx={{
              width: "calc(100% - 60px)",
              objectFit: "cover",
              maxWidth: "210px",
              height: "auto",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          />
          <IconButton sx={{ width: "40px", height: "40px" }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {sortedChatbotList.map(
          (myChatbot, index) =>
            myChatbot && ( // myChatbot이 존재하는지 확인
              <MenuItem
                key={`${index}-my-chatbot-list-drawer`}
                sx={{
                  backgroundColor:
                    currentChatbot?.chatbot_socket_parameter ===
                    myChatbot.chatbot_socket_parameter
                      ? "rgb(0,32,96)"
                      : "white",
                  "&:hover": {
                    backgroundColor:
                      currentChatbot?.chatbot_socket_parameter ===
                      myChatbot.chatbot_socket_parameter
                        ? "rgb(0,32,96)"
                        : "rgba(0, 0, 0, 0.1)", // 원하는 색상으로 변경
                  },
                }}
                onClick={() => {
                  if (myChatbot) {
                    handleWhenChatbotClick(myChatbot, { navigate });
                  }
                }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_DOKGABI_MEDIA_ADDRESS}${myChatbot.character_image?.file_path}`}
                />
                <Stack sx={{ flexGrow: 1, padding: "10px" }}>
                  <Typography
                    sx={{
                      color:
                        currentChatbot?.chatbot_socket_parameter ===
                        myChatbot.chatbot_socket_parameter
                          ? "white"
                          : "rgb(0,32,96)",
                      fontWeight: "bold",
                    }}
                  >
                    {myChatbot.name}
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        currentChatbot?.chatbot_socket_parameter ===
                        myChatbot.chatbot_socket_parameter
                          ? "white"
                          : "black",
                      fontSize: "13px",
                      "@media (max-width: 768px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    {myChatbot.desc}
                  </Typography>
                </Stack>
              </MenuItem>
            )
        )}
      </S.CustomList>
    </Drawer>
  );
}
