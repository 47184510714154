import { Menu } from "@mui/material";
import styled from "styled-components";

interface HeaderContainerProps {
  ishome: string;
  isfromipsispace: string;
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: ${(props) =>
    props.isfromipsispace === "true" ? "space-between" : "flex-end"};
  align-items: center;
  z-index: 500;
  padding: 0 20%; /* 기본값은 웹용 */
  box-sizing: border-box; /* 패딩을 포함한 전체 크기를 적용 */

  background: ${(props) =>
    props.ishome === "true" ? "rgb(0,32,96)" : "white"};

  /* 태블릿 */
  @media (max-width: 1024px) {
    padding: 0 10%;
  }

  /* 모바일 */
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

interface HeaderInnerContainerProps {
  gap: string;
  justify: string;
  minwidth: string;
}

export const HeaderInnerContainer = styled.div<HeaderInnerContainerProps>`
  height: 56px;
  display: flex;
  flex-direction: row;
  // minwidth: ${(props) => props.minwidth};
  justify-content: ${(props) => props.justify};
  align-items: center;
  gap: ${(props) => props.gap};
`;

export const CustomMenu = styled(Menu)`
  & .MuiPaper-root {
    elevation: 0;
    overflow: visible;
    filter: drop-shadow(0rem 5px 10px rgba(0, 0, 0, 0.32));
    margin-top: 34px;
    margin-left: -38px;

    & .MuiAvatar-root {
      width: 40px;
      height: 40px;
      // margin-left: -10px;
      // margin-right: 20px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50px; // 여기 값을 더 크게 설정
      left: 25px;
      width: 5px;
      height: 50px;
      background-color: white;
      transform: translateY(-50%) rotate(45deg);
      z-index: 0;
    }
  }
`;
