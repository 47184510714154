// interface CookieOptions {
//   path?: string;
//   domain?: string;
//   expires?: Date | string | number;
//   maxAge?: number;
//   secure?: boolean;
//   sameSite?: "Strict" | "Lax" | "None";
// }

//21 export function setCookie(
//   name: string,
//   value: string,
//   options: CookieOptions = {}
// ) {
//   let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

//   if (options.path) {
//     cookieString += `; path=${options.path}`;
//   }

//   if (options.domain) {
//     cookieString += `; domain=${options.domain}`;
//   }

//   if (options.expires) {
//     if (options.expires instanceof Date) {
//       cookieString += `; expires=${options.expires.toUTCString()}`;
//     } else if (typeof options.expires === "number") {
//       const date = new Date(options.expires);
//       cookieString += `; expires=${date.toUTCString()}`;
//     } else {
//       cookieString += `; expires=${options.expires}`;
//     }
//   }

//   if (options.maxAge) {
//     cookieString += `; max-age=${options.maxAge}`;
//   }

//   if (options.secure) {
//     cookieString += `; secure`;
//   }

//   if (options.sameSite) {
//     cookieString += `; samesite=${options.sameSite}`;
//   }

//   document.cookie = cookieString;
// }

// export function getCookie(name: string): string | null {
//   const match = document.cookie.match(
//     new RegExp("(^| )" + encodeURIComponent(name) + "=([^;]+)")
//   );
//   return match ? decodeURIComponent(match[2]) : null;
// }

// export function deleteCookie(name: string, path: string, domain: string) {
//   document.cookie = `${name}=; Path=${path}; ${
//     domain ? `Domain=${domain};` : ""
//   } Expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=Lax`;
// }

// -------------개발할 때 밑에꺼

interface CookieOptions {
  path?: string;
  domain?: string;
  expires?: Date | string | number;
  maxAge?: number;
  secure?: boolean;
  sameSite?: "Strict" | "Lax" | "None";
}

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {}
) {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  const isLocalhost = ["localhost", "127.0.0.1"].includes(
    window.location.hostname
  );

  if (options.path) {
    cookieString += `; path=${options.path}`;
  } else {
    cookieString += `; path=/`;
  }

  if (isLocalhost) {
    cookieString += `; domain=localhost`;
  } else if (options.domain) {
    cookieString += `; domain=${options.domain}`;
  }

  if (options.expires) {
    if (options.expires instanceof Date) {
      cookieString += `; expires=${options.expires.toUTCString()}`;
    } else if (typeof options.expires === "number") {
      const date = new Date(options.expires);
      cookieString += `; expires=${date.toUTCString()}`;
    } else {
      cookieString += `; expires=${options.expires}`;
    }
  }

  if (options.maxAge) {
    cookieString += `; max-age=${options.maxAge}`;
  }

  if (options.sameSite) {
    cookieString += `; SameSite=${options.sameSite}`;
  } else {
    cookieString += `; SameSite=Lax`;
  }

  if (!isLocalhost && options.secure) {
    cookieString += `; secure`;
  }

  document.cookie = cookieString;
}

export function getCookie(name: string): string | null {
  const match = document.cookie.match(
    new RegExp("(^| )" + encodeURIComponent(name) + "=([^;]+)")
  );

  return match ? decodeURIComponent(match[2]) : null;
}

export function deleteCookie(
  name: string,
  path: string = "/",
  domain?: string
) {
  const isLocalhost = ["localhost", "127.0.0.1"].includes(
    window.location.hostname
  );

  let cookieString = `${encodeURIComponent(
    name
  )}=; Path=${path}; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;

  // localhost에서는 domain 생략, 배포 환경에서는 설정
  if (isLocalhost) {
    cookieString += `; domain=localhost`;
  } else if (domain) {
    cookieString += `; domain=${domain}`;
  }

  // localhost에서는 secure 생략, 배포 환경에서는 secure 적용
  if (!isLocalhost) {
    cookieString += `; Secure`;
  }

  // localhost에서는 SameSite=None, 배포 환경에서는 SameSite=Lax 설정
  cookieString += `; SameSite=Lax`;

  document.cookie = cookieString;
}
