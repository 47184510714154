import { useQuery } from "@tanstack/react-query";
import {
  DokgabiFlowProps,
  DokgabiFrontElementProps,
} from "../Models/DokgabiFlow";
import { ChattingProps } from "../Components/Chat/Balloon";

async function getMyFlow(id: string): Promise<DokgabiFlowProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyFlow`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetFlowDataQuery(myId: string) {
  return useQuery({
    queryKey: [`${myId}-flow`],
    queryFn: () => getMyFlow(myId),
  });
}

export async function requestChatbot(
  input_message: string,
  flow_socket_name: string,
  user_id: string,
  handleStreamMessage: (
    message: string,
    isFirst: boolean,
    isNotStream: boolean,
    flow_socket_name: string
  ) => void
): Promise<void> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      flow: flow_socket_name,
      message: input_message,
      user_id: user_id,
    }),
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/makeChatbotResponse`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error("응답을 불러오지 못했습니다.");
    }

    // 스트리밍 응답인지 아닌지 확인
    if (response.body) {
      // 스트리밍 응답 처리
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let isFirst = true;
      let message = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          isFirst = true;
          break;
        }

        const chunk = decoder.decode(value, { stream: true });
        message += chunk;
        handleStreamMessage(message, isFirst, false, flow_socket_name); // 스트리밍이므로 isNotStream = false
        isFirst = false;
      }
    } else {
      // 일반 응답 처리 (스트리밍이 아닌 경우)
      const jsonResponse = await response.json();
      const message = JSON.stringify(jsonResponse); // JSON 구조를 문자열로 변환
      handleStreamMessage(message, true, true, flow_socket_name); // 스트리밍이 아니므로 isNotStream = true
    }
  } catch (error) {}
}

async function getMyChatbotFrontElement(
  id: number
): Promise<DokgabiFrontElementProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      id: id,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getMyChatbotFrontElement`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}

export function useGetMyChatbotFrontElement(myId: number) {
  return useQuery({
    queryKey: [`${myId}-front-element`],
    queryFn: () => getMyChatbotFrontElement(myId),
  });
}

export async function getChatbotHistory(
  flow_id: number,
  user_id: string,
  numpage: number
): Promise<ChattingProps[] | null> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // JSON 형식으로 지정
      Authorization: `Bearer ${process.env.REACT_APP_ACCESS_KEY}`,
    },
    body: JSON.stringify({
      flow_id: flow_id,
      user_id: user_id,
      numpage: numpage,
    }),
  };

  const response = await fetch(
    `${process.env.REACT_APP_DOKGABI_API_ADDRESS}/getChatHistory`,
    requestOptions
  );
  if (!response.ok) {
    return null;
  }
  const result = await response.json();

  return result.results;
}
