import { useAtom } from "jotai";
import React from "react";
import { isOpenRecommendQuestionAtom } from "../../../Atoms/ChatAtom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";

export function RecommendQuestionButton() {
  const [isOpenRecommendQuestion, setIsOpenRecommendQuestion] = useAtom(
    isOpenRecommendQuestionAtom
  );

  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: "20px",
        background: "rgb(0,32,96)",
        "&:hover": {
          background: "rgb(0,32,96)",
        },
      }}
      onClick={() => setIsOpenRecommendQuestion((current) => !current)}
    >
      {isOpenRecommendQuestion ? "추천질문 접기" : "추천질문 펼치기"}
      {isOpenRecommendQuestion ? (
        <KeyboardArrowUpIcon />
      ) : (
        <KeyboardArrowDownIcon />
      )}
    </Button>
  );
}
