import React from "react";
import * as S from "./styled";
import {
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ArrowForward, Pending } from "@mui/icons-material";
import { useAtom } from "jotai";
import {
  chatInputValueAtom,
  chattingTypeAtom,
  ChatType,
  isAIMakingResponseAtom,
  isChattingAskAtom,
  searchInputValueAtom,
} from "../../../Atoms/ChatAtom";
import {
  handleClickAtom,
  handleCloseAndChangeTypeAtom,
  handleCloseAtom,
  handleInputFieldKeyDownAtom,
  handleInputValueAtom,
  handleSendBtnAtom,
  whenDownButtonClickAtom,
  whenUpButtonClickAtom,
} from "../../../ViewModels/ChatViewModel";

export function ChatInput() {
  const [chattingType] = useAtom(chattingTypeAtom);

  const [isChattingAsk] = useAtom(isChattingAskAtom);
  const [, handleClick] = useAtom(handleClickAtom);
  const [, handleClose] = useAtom(handleCloseAtom);
  const [, handleCloseAndChange] = useAtom(handleCloseAndChangeTypeAtom);

  const [chatInputValue] = useAtom(chatInputValueAtom);
  const [searchInputValue] = useAtom(searchInputValueAtom);
  const [isAIMakingResponse] = useAtom(isAIMakingResponseAtom);
  const [, handleInputValue] = useAtom(handleInputValueAtom);
  const [, onKeyDown] = useAtom(handleInputFieldKeyDownAtom);
  const [, sendMessage] = useAtom(handleSendBtnAtom);

  const [, whenDownButtonClick] = useAtom(whenDownButtonClickAtom);
  const [, whenUpButtonClick] = useAtom(whenUpButtonClickAtom);

  return (
    <>
      <S.CustomMenu
        anchorEl={isChattingAsk}
        id="account-menu"
        open={Boolean(isChattingAsk)}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "left", vertical: "top" }} // 변경
        anchorOrigin={{ horizontal: "left", vertical: "top" }} // 변경
      >
        <MenuItem onClick={() => handleCloseAndChange(ChatType.ASK)}>
          <ListItemIcon>
            <ChatBubbleOutlineIcon />
          </ListItemIcon>
          <Typography>질문 검색</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleCloseAndChange(ChatType.SEARCH)}>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <Typography>대화 내용 검색</Typography>
        </MenuItem>
      </S.CustomMenu>

      <S.InputContainer>
        <S.ChattingTypeButton onClick={handleClick}>
          {chattingType === ChatType.ASK ? (
            <ChatBubbleOutlineIcon sx={{ color: "white" }} />
          ) : (
            <SearchIcon sx={{ color: "white" }} />
          )}
          <KeyboardArrowDownIcon sx={{ color: "white" }} />
        </S.ChattingTypeButton>

        <S.CustomInputBase
          className="chatbot-inputbse-custom"
          placeholder={
            chattingType === ChatType.ASK
              ? isAIMakingResponse
                ? "답변 생성 중"
                : "질문 입력해주세요."
              : "검색어 입력해주세요."
          }
          multiline
          maxRows={9}
          value={
            chattingType === ChatType.ASK ? chatInputValue : searchInputValue
          }
          onChange={
            isAIMakingResponse
              ? () => null
              : (e) => handleInputValue(e.target.value)
          }
          onKeyDown={
            chattingType === ChatType.ASK && !isAIMakingResponse
              ? (e) => onKeyDown(e)
              : () => null
          }
        />

        <Box
          sx={{
            background: isAIMakingResponse
              ? "rgb(0,32,96)"
              : chattingType === ChatType.ASK
              ? chatInputValue === ""
                ? "#8f8f8f"
                : "rgb(0,32,96)"
              : searchInputValue === ""
              ? "#8f8f8f"
              : "rgb(0,32,96)",
            width: "auto",
            height: "40px",
            borderRadius: "20px",
            boxSizing: "border-box",
            padding: "8px",
            display: "flex",
            flexDirection: "row",
            gap: "5px",
          }}
        >
          {chattingType === ChatType.ASK ? (
            !isAIMakingResponse ? (
              <IconButton onClick={() => sendMessage()} sx={{ padding: 0 }}>
                <ArrowForward sx={{ color: "white" }} />
              </IconButton>
            ) : (
              <IconButton sx={{ padding: 0 }}>
                <Pending sx={{ color: "white" }} />
              </IconButton>
            )
          ) : (
            <>
              <IconButton
                onClick={() => whenDownButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowDownIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton
                onClick={() => whenUpButtonClick()}
                sx={{ padding: 0 }}
              >
                <KeyboardArrowUpIcon sx={{ color: "white" }} />
              </IconButton>
            </>
          )}
        </Box>
      </S.InputContainer>
    </>
  );
}
