import { atom } from "jotai";
import { drawerOpenAtom } from "../Atoms/DrawerAtom";
import { DokgabiFlowProps } from "../Models/DokgabiFlow";
import {
  chatInputValueAtom,
  chattingTypeAtom,
  ChatType,
  currentChatbotAtom,
  isChattingAskAtom,
  isOpenRecommendQuestionAtom,
  recommendCurrentPageAtom,
  searchInputValueAtom,
} from "../Atoms/ChatAtom";

export const setStateDrawerAtom = atom(
  (get) => get(drawerOpenAtom),
  (get, set, open: boolean, event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    set(drawerOpenAtom, open);
  }
);

export const handleWhenChatbotClickAtom = atom(
  (get) => get(currentChatbotAtom),
  (get, set, chatbot: DokgabiFlowProps, { navigate }) => {
    navigate("/chat");
    set(currentChatbotAtom, chatbot);
    set(isChattingAskAtom, null);
    set(chattingTypeAtom, ChatType.ASK);
    set(isOpenRecommendQuestionAtom, false);
    set(recommendCurrentPageAtom, 0);
    set(searchInputValueAtom, "");
    set(chatInputValueAtom, "");
  }
);
