import { atom } from "jotai";
import {
  chatbotMenuAtom,
  isFromIpsispaceAtom,
  userIdAtom,
} from "../Atoms/RootAtom";
import { v4 as uuidv4 } from "uuid"; // uuid 라이브러리가 필요합니다.
import { DokgabiFlowProps } from "../Models/DokgabiFlow";
import {
  chatInputValueAtom,
  chattingTypeAtom,
  ChatType,
  currentChatbotAtom,
  isChattingAskAtom,
  isOpenRecommendQuestionAtom,
  recommendCurrentPageAtom,
  searchInputValueAtom,
} from "../Atoms/ChatAtom";
import { getCookie, setCookie } from "../Utils/CookieUtil";

export const checkFromIpsispaceAtom = atom(null, (get, set, { navigate }) => {
  const referrer = document.referrer;

  if (referrer.includes("ipsispace.com")) {
    set(isFromIpsispaceAtom, true);

    const user = getCookie("userId");

    if (user) {
      set(userIdAtom, user);
    } else {
      const newUserId = uuidv4();
      setCookie("userId", newUserId, {
        path: "/",
        sameSite: "Lax",
        domain: ".ipsispace.com",
        secure: true,
      });
      set(userIdAtom, newUserId);
    }
  } else {
    set(isFromIpsispaceAtom, false);
    navigate("/");
  }
});

export const initializeAtom = atom(null, (get, set) =>
  set(isFromIpsispaceAtom, false)
);

export const handleClickAtom = atom(
  null,
  (_, set, event: React.MouseEvent<HTMLDivElement>) => {
    set(chatbotMenuAtom, event.currentTarget);
  }
);

export const handleCloseAtom = atom(null, (_, set) => {
  set(chatbotMenuAtom, null);
});

export const handleCloseAndChangeTypeAtom = atom(
  null,
  (get, set, chatbot: DokgabiFlowProps) => {
    set(chatbotMenuAtom, null);
    set(currentChatbotAtom, chatbot);
    set(isChattingAskAtom, null);
    set(chattingTypeAtom, ChatType.ASK);
    set(isOpenRecommendQuestionAtom, false);
    set(recommendCurrentPageAtom, 0);
    set(searchInputValueAtom, "");
    set(chatInputValueAtom, "");
  }
);
