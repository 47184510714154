import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { useAtom } from "jotai";
import { isFromIpsispaceAtom } from "../../Atoms/RootAtom";
import { setStateDrawerAtom } from "../../ViewModels/DrawerViewModel";

export function Home() {
  const [, toggleDrawer] = useAtom(setStateDrawerAtom);
  const [isFromIpsispace] = useAtom(isFromIpsispaceAtom);

  return (
    <Stack
      sx={{
        background: "rgb(0,32,96)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="img"
        sx={{
          objectFit: "cover",
          width: "100%", // 부모 요소의 너비에 맞춰 이미지가 크기를 조정
          maxWidth: "300px", // 최대 너비 설정 (필요에 따라 값 조정 가능)
          height: "auto", // 이미지의 비율을 유지하면서 높이를 자동 조정
        }}
        src="logo_white.png"
        alt="logo"
      />
      <Box
        sx={{
          marginTop: "10px",
          padding: "10px 30px",
          borderRadius: "45px",
          background: "white",
          color: "rgb(0,32,96)",
          cursor: "pointer",
          textAlign: "center",
        }}
        onClick={
          !isFromIpsispace
            ? () => (window.location.href = "https://ipsispace.com/login/")
            : (e) => toggleDrawer(true, e)
        }
      >
        {!isFromIpsispace ? (
          <>
            <span>AI 컨설턴트는 입시스페이스 페이지에서</span>
            <br />
            <span>로그인 후에 사용하실 수 있습니다.</span>
          </>
        ) : (
          "AI 컨설턴트"
        )}
      </Box>

      {!isFromIpsispace && (
        <Button
          variant="text"
          sx={{
            marginTop: "20px",
            color: "white",
          }}
          onClick={() =>
            (window.location.href = "https://ipsispace.com/login/")
          }
        >
          로그인하러 가기
        </Button>
      )}
    </Stack>
  );
}
