import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import * as S from "./Components/Root/styled";
import { CustomDrawer } from "./Components/Drawer";
import { Header } from "./Components/Header";
import { useAtom } from "jotai";
import {
  checkFromIpsispaceAtom,
  initializeAtom,
} from "./ViewModels/RootViewModel";

function Root() {
  const [, checkFromIpsispace] = useAtom(checkFromIpsispaceAtom);
  const [, initialize] = useAtom(initializeAtom);
  const navigate = useNavigate();

  useEffect(() => {
    checkFromIpsispace({ navigate });

    return () => initialize();
  }, []);

  return (
    <S.FullContainer>
      <CustomDrawer />
      <Header />
      <S.ContentContainer>
        <Outlet />
      </S.ContentContainer>
    </S.FullContainer>
  );
}

export default Root;
